import React from "react";

export const Team = (props) => {
  return (
    <div id="team" className="text-center">
      <div className="container">
        <div className="col-md-8 col-md-offset-2 section-title">
          <h2>Meet the Team</h2>
          <p>
            We are the Badmins! This started off with me and Jm3 until MrT gave us a hand. Bagsee later came along and schooled all of us. Maddog and Dazza joined when we needed more help and the squad was born! We have since seen MissingP_rt FINALLY join the team! Thor, Godai, Frostavetur, Dragon and Cthulhamonster are here to keep yall in line and to help with anything and everything! Honestly couldn't do this without all of these awesome people! 
          </p>
        </div>
        <div id="row">
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.name}-${i}`} className="col-md-3 col-sm-6 team">
                  <div className="thumbnail">
                    {" "}
                    <img src={d.img} alt="..." className="team-img" />
                    <div className="caption">
                      <h4>{d.name}</h4>
                      <p>{d.job}</p>
                    </div>
                  </div>
                </div>
              ))
            : "loading"}
        </div>
      </div>
    </div>
  );
};
