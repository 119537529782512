import React from "react";
import { IconContext } from "react-icons";
import { FaDiscord, FaPatreon, FaYoutube } from "react-icons/fa";

export const Patreon = (props) => {
  return (
    <div id="patreon" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>The Pilpoh Community Server!</h2>
          <p>
            Become a vital part of our community by joining the Pilpoh Patreon! Your support helps keep our multiplayer Modded Minecraft server running, dedicated to the most popular modpack in our community. Your contributions directly fund the server costs, ensuring a high-quality experience for everyone.
          </p>
          <p>
            As an Iron-tier member or above, you'll gain exclusive access to this server, where you can embark on adventures, collaborate with fellow players, and immerse yourself in the endless possibilities of modded Minecraft. Whether you're a seasoned explorer or a budding architect, join our Patreon today and become an essential part of our growing Minecraft community!
          </p>
          <p>
            Click the icon below!
          </p>
        </div>
        <div className="row">
          <IconContext.Provider value={{ color: 'white', size: '100px' }}>
            <a href={"https://www.patreon.com/Pilpoh"} target="_blank" rel="noopener noreferrer">
              <FaPatreon />
            </a>
          </IconContext.Provider>
        </div>
      </div>
    </div>
  );
};
